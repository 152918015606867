// eslint-disable-next-line import/extensions
import TaxOfficeClientList from '@/views/apps/clients/TaxOfficeClientList'
// eslint-disable-next-line import/extensions
import AddTaxOfficeClient from '@/views/apps/clients/AddTaxOfficeClient'
// eslint-disable-next-line import/extensions
import TaxOfficeClientPreview from '@/views/apps/clients/TaxOfficeClientPreview';
// eslint-disable-next-line import/extensions
import WebAppClientList from '@/views/apps/clients/WebAppClientList'

import guard from '@/router/routes/guard'
// eslint-disable-next-line import/extensions
import WebAppClientPreview from '@/views/apps/clients/WebAppClientPreview'

export default [
  {
    path: '/tax-office-client',
    name: 'tax-office-client-list',
    component: TaxOfficeClientList,
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeAdmin(to, from, next)
    },
  },
  {
    path: '/tax-office-client/add',
    name: 'tax-office-client-add',
    component: AddTaxOfficeClient,
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/tax-office-client/preview/:id',
    name: 'tax-office-client-preview',
    component: TaxOfficeClientPreview,
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/web-app-client',
    name: 'web-app-client-list',
    component: WebAppClientList,
    beforeEnter: (to, from, next) => {
      guard.protectAdmin(to, from, next)
    },
  },
  {
    path: '/web-app-client/preview/:id',
    name: 'web-app-client-preview',
    component: WebAppClientPreview,
    beforeEnter: (to, from, next) => {
      guard.protectAdmin(to, from, next)
    },
  },
]
