var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"companyInfoForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nazwa","label-for":"account-username"}},[_c('validation-provider',{attrs:{"name":"Nazwa","vid":"company-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-name","placeholder":"Nazwa","state":errors.length > 0 ? false:null,"name":"username"},model:{value:(_vm.currentCompany.Title),callback:function ($$v) {_vm.$set(_vm.currentCompany, "Title", $$v)},expression:"currentCompany.Title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"NIP","label-for":"company-id"}},[_c('b-form-input',{attrs:{"name":"company_id","placeholder":"NIP","disabled":true},model:{value:(_vm.currentCompany.NIP),callback:function ($$v) {_vm.$set(_vm.currentCompany, "NIP", $$v)},expression:"currentCompany.NIP"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"REGON","label-for":"regon"}},[_c('validation-provider',{attrs:{"name":"REGON","vid":"regon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"regon","name":"regon","state":errors.length > 0 ? false:null,"placeholder":"REGON"},model:{value:(_vm.currentCompany.Regon),callback:function ($$v) {_vm.$set(_vm.currentCompany, "Regon", $$v)},expression:"currentCompany.Regon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Adres - województwo","label-for":"register-state"}},[_c('validation-provider',{attrs:{"name":"Województwo","vid":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"state","name":"register_state","state":errors.length > 0 ? false:null,"placeholder":"Adres - województwo"},model:{value:(_vm.currentCompany.Province),callback:function ($$v) {_vm.$set(_vm.currentCompany, "Province", $$v)},expression:"currentCompany.Province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Adres - powiat","label-for":"register-district"}},[_c('b-form-input',{attrs:{"name":"register_district","placeholder":"Adres - powiat"},model:{value:(_vm.currentCompany.District),callback:function ($$v) {_vm.$set(_vm.currentCompany, "District", $$v)},expression:"currentCompany.District"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Adres - gmina","label-for":"register-community"}},[_c('validation-provider',{attrs:{"name":"Gmina","vid":"community","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"community","name":"register_community","state":errors.length > 0 ? false:null,"placeholder":"Adres - gmina"},model:{value:(_vm.currentCompany.Community),callback:function ($$v) {_vm.$set(_vm.currentCompany, "Community", $$v)},expression:"currentCompany.Community"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Adres - kod pocztowy","label-for":"register-zip-code"}},[_c('validation-provider',{attrs:{"name":"Kod pocztowy","vid":"zip-code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"zip-code","name":"register_zip_code","state":errors.length > 0 ? false:null,"placeholder":"Adres - kod pocztowy"},model:{value:(_vm.currentCompany.ZipCode),callback:function ($$v) {_vm.$set(_vm.currentCompany, "ZipCode", $$v)},expression:"currentCompany.ZipCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Adres - miejscowość","label-for":"register-city"}},[_c('validation-provider',{attrs:{"name":"Miejscowość","vid":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","name":"register_city","state":errors.length > 0 ? false:null,"placeholder":"Adres - miejscowość"},model:{value:(_vm.currentCompany.City),callback:function ($$v) {_vm.$set(_vm.currentCompany, "City", $$v)},expression:"currentCompany.City"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.updateCompanyData}},[_vm._v(" "+_vm._s(_vm.$t('Save Changes'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }