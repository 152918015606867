import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  costs: [

    {
      id: 40,
      issuedDate: '17 Jul 2019',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: '3M LOGISTICS Sp. z o.o.',
        companyEmail: 'brenda49@taylor.info',
        country: 'Haiti',
        contact: '(226) 204-8287',
        name: 'Stephanie Burns',
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      avatar: require('@/assets/images/avatars/10-small.png'),
      invoiceStatus: 'Expired',
      balance: 0,
      dueDate: '15 Dec 2019',
      paymentDetails: {
        paymentMethood: 'Bank Account',
      },

    },

    {
      id: 4989,
      issuedDate: '17 Jul 2019',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: '3M LOGISTICS Sp. z o.o.',
        companyEmail: 'brenda49@taylor.info',
        country: 'Haiti',
        contact: '(226) 204-8287',
        name: 'Stephanie Burns',
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      avatar: require('@/assets/images/avatars/10-small.png'),
      invoiceStatus: 'Expired',
      balance: 0,
      dueDate: '15 Dec 2019',
      paymentDetails: {
        paymentMethood: 'Bank Account',
      },
      debtHistory: [{
        title: 'Wysłano upomnienie',
        subtitle: 'Wysłano przedsądowe wezwanie do zapłaty',
        time: '19 Dec 2019',
        attachment: {
          title: 'wezwanie-do-zapłaty.pdf',
        },

      }, {
        title: 'Wysłąno SMS',
        subtitle: 'Wysłano powiadomienie sms',
        time: '17 Dec 2019',

      }, {
        title: 'Wysłano ponownie fakture',
        subtitle: 'Wysłano fakture z przypomnieniem oraz pieczęcią windykacyjną',
        time: '12 Dec 2019',
        attachment: {
          title: 'example invoice.pdf',
        },

      }],

    },

  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return costs
// ------------------------------------------------
mock.onGet('/documents/costs').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.costs.filter(
    cost =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (cost.client.companyEmail.toLowerCase().includes(queryLowered) ||
        cost.client.name.toLowerCase().includes(queryLowered)) &&
      cost.invoiceStatus === (status || cost.invoiceStatus),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      costs: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Costs document
// ------------------------------------------------
mock.onGet(/\/documents\/costs\/\d+/).reply(config => {
  // Get event id from URL
  let costId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  costId = Number(costId)

  const costIndex = data.costs.findIndex(e => e.id === costId)
  const cost = data.costs[costIndex]
  const responseData = {
    cost,
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    },
  }

  if (cost) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------

// mock.onGet('/apps/invoice/clients').reply(() => {
//   const clients = data.invoices.map(invoice => invoice.client)
//   return [200, clients.slice(0, 5)]
// })
