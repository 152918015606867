import guard from '@/router/routes/guard'

export default [
  {
    path: '/dashboard',
    name: 'dashboard-main',
    component: () => import('@/views/dashboard/main/MainDashboard.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
]
