<template>
  <div class="single-worker--inner">
    <div class="single-worker--header p-5 bg-white mb-2">
      <b-row>

        <b-col
          cols="12"
          lg="6"
        >
          <h1>{{ clientData.Title }}</h1>
        </b-col>
      </b-row>
    </div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >

      <!-- Profil pracownika -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Profil pracownika</span>
        </template>

        <WorkerProfile />
      </b-tab>

      <!-- Role -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Role</span>
        </template>
      </b-tab>

      <!-- Dane pracownika-->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Dane pracownika</span>
        </template>

        <WorkerInfo />
      </b-tab>

      <!-- Deklaracje i formularze-->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Deklaracje i formularze</span>
        </template>

        <WorkerDeclarations />
      </b-tab>

      <!-- Umowy i rachunki-->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Umowy i rachunki</span>
        </template>
      </b-tab>

      <!-- Listy płac-->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Listy płac</span>
        </template>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      clientData: [],
    }
  },
  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Client`, config).then(response => {
        // eslint-disable-next-line radix,prefer-destructuring
        this.clientData = response.data.filter(client => client.id === this.$route.params.id)[0]
      }).catch(error => {
        console.log(error)
        Swal.fire('Nie można pobrać danych klienta', '', 'error')
      })
    },
  },
}
</script>
