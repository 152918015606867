<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-card-header>
      <h2>
        Dodaj klienta do biura
      </h2>
    </b-card-header>
    <b-card-body>
      <b-form
        class="p-2"
        @submit.prevent
      >
        <!-- form -->
        <validation-observer
          ref="registerForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="addClient"
          >
            <b-form-group
              label="Nazwa"
              label-for="client-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nazwa firmy"
                vid="client_name"
                rules="required"
              >
                <b-form-input
                  id="client-name"
                  v-model="clientName"
                  name="client_name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="NIP Firmy"
              label-for="register-company-id"
            >
              <validation-provider
                #default="{ errors }"
                name="NIP firmy"
                vid="company-id"
                rules="required|numeric|min:10|max:10"
              >
                <b-form-input
                  id="company-id"
                  v-model="nip"
                  name="company-id"
                  :state="errors.length > 0 ? false:null"
                  placeholder="0000000000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Email"
              label-for="register-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="register-email"
                  v-model="userEmail"
                  name="register-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="register-password"
              label="Hasło"
            >
              <validation-provider
                #default="{ errors }"
                name="Hasło"
                vid="password"
                rules="required|min:8|max:20"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password confirmation -->
            <b-form-group
              label-for="register-password-confirmation"
              label="Potwierdzenie hasła"
            >
              <validation-provider
                #default="{ errors }"
                name="Potwierdzenie hasła"
                vid="password-confirmation"
                rules="required|min:8|max:20|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="register-password-confirm"
                    v-model="passwordConfirmation"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="register-password-confirmation"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
            >
              Dodaj klienta
            </b-button>
          </b-form>
        </validation-observer>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  confirmed,
  email,
  max,
  min,
  numeric,
  required,
} from '@core/utils/validations/validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'AddTaxOfficeClient',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      nip: '',
      userEmail: '',
      clientName: '',
      password: '',
      passwordConfirmation: '',
      // validation
      required,
      email,
      max,
      min,
      numeric,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    addClient() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          const token = localStorage.getItem('accessToken')
          const config = { headers: { Authorization: `Bearer ${token}` } }
          axios.post(`${process.env.VUE_APP_API_URL}Client`, {
            Name: this.clientName,
            email: this.userEmail,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            NIP: this.nip.replace(/\s/g, ''),
          }, config).then(() => {
            Swal.fire('Pomyślnie dodano klienta', '', 'success')
            this.$router.push({ name: 'tax-office-client-list' })
          }).catch(error => {
            let swalMessage = 'Coś poszło nie tak'
            if (error.response.data.message === 'Error NIP') {
              swalMessage = 'NIP jest już używany'
            }
            Swal.fire(swalMessage, '', 'error')
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
