<template>
  <b-card>
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">
        {{ $t('Add New User') }}
      </h5>
    </div>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Username -->
        <validation-provider
          #default="validationContext"
          name="Imię i nazwisko"
          rules="required"
        >
          <b-form-group
            label="Imię i nazwisko"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.name"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Email -->
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="required|email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Password -->
        <validation-provider
          #default="validationContext"
          name="Hasło"
          rules="required|min:8"
        >
          <b-form-group
            label="Hasło"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="userData.password"
              type="password"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Phone number -->
        <validation-provider
          #default="validationContext"
          name="Numer telefonu"
          rules="required|digits:9"
        >
          <b-form-group
            label="Numer telefonu"
            label-for="phone-number"
          >
            <b-form-input
              id="phone-number"
              v-model="userData.tel"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- User role -->
        <validation-provider
          #default="validationContext"
          name="Rola użytkownika"
          rules="required"
        >
          <b-form-group
            label="Rola użytkownika"
            label-for="role"
          >
            <b-form-select
              id="role"
              v-model="userData.type"
              :state="getValidationState(validationContext)"
              :options="roleOptions"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <feather-icon
              icon="PlusSquareIcon"
              size="18"
              class="mr-50"
            />
            Dodaj
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'pages-account-setting' }"
          >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              size="18"
              class="mr-50"
            />
            Anuluj
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormSelect, BFormInvalidFeedback, BButton, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      roleOptions: [],
    }
  },
  mounted() {
    const token = localStorage.getItem('accessToken')
    const config = { headers: { Authorization: `Bearer ${token}` } }
    axios.get(`${process.env.VUE_APP_API_URL}Roles`, config).then(response => {
      response.data.forEach(row => {
        this.roleOptions.push({
          text: row.Name,
          value: row.id,
        })
      })
    }).catch(error => {
      console.log(error)
    })
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      email: '',
      password: '',
      tel: '',
      type: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const onSubmit = () => {
      axios.post(`${process.env.VUE_APP_API_URL}User`, userData.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        emit('refetch-data')
        emit('update:is-add-new-user-sidebar-active', false)
        Swal.fire(
          'Pomyślnie dodano użytkownika',
          '',
          'success',
        )
      }).catch(error => {
        console.log(error.response.data.data)
        if (error.response.data.data.email !== undefined && error.response.data.data.email[0] === 'The email has already been taken.') {
          Swal.fire(
            'Email użytkownika jest już zajęty',
            '',
            'error',
          )
        } else {
          Swal.fire(
            'Coś poszło nie tak!',
            '',
            'error',
          )
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
