<template>
  <div class="single-worker--inner">
    <div class="single-worker--header p-5 bg-white mb-2">
      <b-row>

        <b-col
          cols="12"
          lg="6"
        >
          <h1>{{ clientData.Title }}</h1>
        </b-col>
      </b-row>
    </div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >

      <!-- Profil pracownika -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Dane biura</span>
        </template>

        <AccountSettingCompanyInfo />
      </b-tab>
    </b-tabs>
  </div>

</template>

<script>
import {
  BCol,
  BRow,
  BTab,
  BTabs,
} from 'bootstrap-vue'

// tabs
import axios from 'axios'
import Swal from 'sweetalert2'
// eslint-disable-next-line import/extensions
import AccountSettingCompanyInfo from '@/views/pages/account-setting/AccountSettingCompanyInfo'

export default {
  components: {
    AccountSettingCompanyInfo,
    BTabs,
    BTab,
    BCol,
    BRow,
  },
  data() {
    return {
      clientData: [],
    }
  },
  mounted() {
    const token = localStorage.getItem('accessToken')

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}Client`, config)
      .then(response => {
        // eslint-disable-next-line radix,prefer-destructuring
        this.clientData = response.data.filter(client => client.id === parseInt(this.$route.params.id))[0]
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
  },

}
</script>
