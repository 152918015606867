<template>
  <b-card>
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">
        {{ $t('Edit User Data') }}
      </h5>
    </div>

    <!-- BODY -->
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <!-- Username -->
        <validation-provider
          #default="validationContext"
          name="Imię i nazwisko"
          rules="required"
        >
          <b-form-group
            label="Imię i nazwisko"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="user.name"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Phone number -->
        <validation-provider
          #default="validationContext"
          name="Numer telefonu"
          rules="required|digits:9"
        >
          <b-form-group
            label="Numer telefonu"
            label-for="phone-number"
          >
            <b-form-input
              id="phone-number"
              v-model="user.tel"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="invalid"
            variant="primary"
            class="mr-2"
            @click="updateUser"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
              class="mr-50"
            />
            Zatwierdź
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'pages-account-setting' }"
          >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              size="18"
              class="mr-50"
            />
            Anuluj
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      user: {
        name: '',
        email: '',
        password: '',
        city: '',
        dateOfBirth: '',
        pesel: '',
        tel: '',
        status: '',
        hasCreationModificationUserPrivilege: false,
        hasModificationDataBusinessPrivilege: false,
      },
    }
  },
  mounted() {
    const token = localStorage.getItem('accessToken')
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}User/${this.$route.params.id}`, config).then(response => {
      this.user.name = response.data.user.Name
      this.user.email = response.data.user.email
      this.user.tel = response.data.user.Tel
      this.user.pesel = response.data.user.pesel
      this.user.city = response.data.user.city
      this.user.dateOfBirth = response.data.user.date_of_birth
    }).catch(error => {
      console.log(error)
    })
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      email: '',
      tel: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const onSubmit = () => {
      emit('refetch-data')
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    updateRole() {
      const accessToken = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${accessToken}` } }
      const putData = { Creation_Modification_Users: this.user.hasCreationModificationUserPrivilege }
      axios.put(`${process.env.VUE_APP_API_URL}Roles/${this.$route.params.id}`, putData, config).then(() => {
        const swalMessage = 'Zmieniono uprawnienia'
        Swal.fire(swalMessage, '', 'success')
      }).catch(error => {
        console.log(error)
        Swal.fire('Coś poszło nie tak', '', 'error')
      })
    },
    updateUser() {
      const accessToken = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${accessToken}` } }
      const putData = {
        name: this.user.name,
        email: this.user.email,
        tel: this.user.tel,
        pesel: this.user.pesel,
        city: this.user.city,
        active: true,
      }
      axios.put(`${process.env.VUE_APP_API_URL}User/${this.$route.params.id}`, putData, config).then(() => {
        Swal.fire('Zaktualizowano dane', '', 'success')
      }).catch(error => {
        console.log(error)
        Swal.fire('Coś poszło nie tak', '', 'error')
      })
    },
  },
}
</script>
