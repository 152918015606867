import { $themeBreakpoints } from '@themeConfig'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    activeCompany: null,
    activeCompanyName: null,
    activeCompanyBusinessData: null,
    clients: [],
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_ACTIVE_COMPANY(state, val) {
      state.activeCompany = val
    },
    UPDATE_ACTIVE_COMPANY_NAME(state, val) {
      state.activeCompanyName = val
    },
    UPDATE_ACTIVE_COMPANY_BUSINESS_DATA(state, val) {
      state.activeCompanyBusinessData = val
    },
    UPDATE_TAX_OFFICE_CLIENTS(state, val) {
      state.clients = val
    },
  },
  actions: {
    logout(context) {
      // Remove userData from localStorage
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('userRole')
      context.commit('UPDATE_ACTIVE_COMPANY', null)
      // Redirect to login page
      router.push({ name: 'auth-login' })
    },
  },
}
